import { AnyAction, bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import Navigation from '../components/Navigation';
import { EvaluationState } from '../features/evaluations/evaluationSlice';
import { popLoading, pushLoading } from '../features/loading/loadingSlice';
import req from '../Axios';
import ResultCard from '../components/ResultCard';
import { Blend } from '../types';

type State = {
  blends: Blend[];
};

type Props = {
  evaluations: EvaluationState;
  pushLoading: typeof pushLoading;
  popLoading: typeof popLoading;
};

class Learn extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      blends: []
    };
  }

  async componentDidMount() {
    try {
      this.props.pushLoading('blends');
      const response = await req.get(`/blends`);

      this.setState(
        {
          blends: response.data
        },
        () => this.props.popLoading('blends')
      );
    } catch (e) {
      this.props.popLoading('blends');
      console.log(e);
    }
  }

  render() {
    const resultCards: ReactNode[] = [];

    const { active } = this.props.evaluations;

    active?.results.forEach((r) => {
      resultCards.push(
        <ResultCard result={r} blends={this.state.blends} key={`${r.blend}-${r.category}`} />
      );
    });

    return (
      <div>
        <Navigation />
        {resultCards}
      </div>
    );
  }
}

function mapStateToProps(state: { evaluations: EvaluationState }) {
  const { evaluations } = state;
  return { evaluations };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators(
    {
      pushLoading,
      popLoading
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Learn);
