import { AnyAction, Dispatch, bindActionCreators } from '@reduxjs/toolkit';
import { Component } from 'react';
import { connect } from 'react-redux';
import Navigation from '../components/Navigation';
import {
  Answer,
  EvaluationState,
  setActiveEvaluation
} from '../features/evaluations/evaluationSlice';
import { popLoading, pushLoading } from '../features/loading/loadingSlice';
import { Question, QuestionsState } from '../features/questions/questionSlice';
import req from '../Axios';
import { fireToast } from '../features/toast/toastSlice';
import { Navigate } from 'react-router-dom';

interface Props {
  pushLoading: typeof pushLoading;
  popLoading: typeof popLoading;
  setActiveEvaluation: typeof setActiveEvaluation;
  fireToast: typeof fireToast;
  questions: QuestionsState;
  evaluations: EvaluationState;
}

interface State {
  name: string;
  partialId: string;
  partialAnswers: Answer[];
  evaluation: Answer[];
  activeQuestion: Question;
  activeIndex: number;
  percentComplete: string;
  isLoading: boolean;
  learningId: string | undefined;
}

class Evaluation extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      name: '',
      partialId: '',
      partialAnswers: [],
      evaluation: [],
      activeQuestion: {
        _id: '',
        question: '',
        section: ''
      },
      activeIndex: 0,
      percentComplete: '0',
      isLoading: false,
      learningId: undefined
    };
  }

  async componentDidMount() {
    this.props.pushLoading('evaluation');
    this.props.popLoading('evaluation-splash');

    // get questions
    const { questions } = this.props;

    // parse URL params for name and partialId
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let partialId = urlParams.get('partial');
    if (!partialId) partialId = '';

    // validate partialId
    const regex = new RegExp('^[a-fA-F0-9]{24}$');
    if (!regex.test(partialId)) throw 'Invalid partial identifier';

    // get partial from API
    try {
      const partial = await req.get(`/partials/${partialId}`);

      // calculate percent complete
      let percentComplete = (partial.data.answers.length / questions.length) * 100;

      if (percentComplete < 0) {
        percentComplete = percentComplete * -1;
      }

      const percentCompleteFixed = percentComplete.toFixed(0);

      if (partial.data.answers.length == questions.length) {
        // partial already completed
        this.setState(
          {
            name: partial.data.name,
            partialId: partial.data._id,
            evaluation: partial.data.answers,
            activeIndex: partial.data.answers.length - 1,
            activeQuestion: questions[partial.data.answers.length - 1],
            percentComplete: percentCompleteFixed
          },
          async () => {
            this.props.popLoading('evaluation');
            const answer = partial.data.answers[questions.length - 1];
            await this.handleAnswerQuestion(answer);
          }
        );
      } else {
        // Complete evaluation with partial answers and set active index
        this.setState(
          {
            name: partial.data.name,
            partialId: partial.data._id,
            evaluation: partial.data.answers,
            activeIndex: partial.data.answers.length,
            activeQuestion: questions[partial.data.answers.length],
            percentComplete: percentCompleteFixed
          },
          () => this.props.popLoading('evaluation')
        );
      }
    } catch (e: any) {
      this.props.popLoading('evaluation');
      console.log(e);

      if (typeof e === 'string') {
        this.props.fireToast({ message: e, failure: true });
      } else {
        if (e.response.data.message) {
          this.props.fireToast({ message: e.response.data.message, failure: true });
        } else {
          this.props.fireToast({ message: 'Something went wrong', failure: true });
        }
      }
    }
  }

  submitEvaluation = async () => {
    try {
      const payload = {
        name: this.state.name ? this.state.name : null,
        answers: [...this.state.evaluation],
        partialId: this.state.partialId
      };

      const response = await req.post(`/evaluations`, payload);

      return response.data;
    } catch (e) {
      console.log(e);
    }
  };

  handleAnswerQuestion = async (bool: boolean) => {
    const { activeIndex, activeQuestion } = this.state;
    const { questions } = this.props;

    const payload: Answer = {
      questionId: activeQuestion._id,
      question: activeQuestion.question,
      section: activeQuestion.section,
      answer: bool
    };

    const percentComplete = (((this.state.evaluation.length + 1) / questions.length) * 100).toFixed(
      0
    );

    // start loading and lock form
    this.setState(
      (state: State) => ({
        evaluation: [...state.evaluation, payload],
        percentComplete: percentComplete,
        isLoading: true
      }),
      async () => {
        try {
          // persist partial
          await req.put(`/partials/${this.state.partialId}`, payload);
        } catch (e: any) {
          console.log(e);
        }

        try {
          // end of form, submit
          if (activeIndex === questions.length - 1) {
            const evaluation = await this.submitEvaluation();
            // if success, navigate to results
            this.props.setActiveEvaluation(evaluation);

            // TO DO: NAVIGATE TO LEARN
            this.setState({
              learningId: evaluation._id
            });
          } else {
            this.setState((state: State) => ({
              activeIndex: state.activeIndex + 1,
              activeQuestion: questions[state.activeIndex + 1],
              isLoading: false
            }));
          }
        } catch (e: any) {
          if (typeof e === 'string') {
            this.props.fireToast({ message: e, failure: true });
          } else {
            if (e.response.data.message) {
              this.props.fireToast({ message: e.response.data.message, failure: true });
            } else {
              this.props.fireToast({ message: 'Something went wrong', failure: true });
            }
          }
        }
      }
    );
  };

  render() {
    const { active } = this.props.evaluations;
    const { activeQuestion, isLoading, learningId } = this.state;
    return (
      <div>
        {!!learningId && <Navigate to={`/learn/${active?._id}`} replace={false} />}
        <Navigation showNewTestButton={false} />
        <div className="section">
          <div className="content">
            <div className="eval-box mb-4 has-text-centered">
              {this.state.percentComplete}% complete
            </div>
            <div className="box eval-box">
              <p className="eval-question">{activeQuestion.question}</p>
              <button
                className="button is-link eval-button"
                onClick={() => this.handleAnswerQuestion(true)}
                disabled={isLoading}>
                True
              </button>
              <button
                className="button is-link eval-button"
                onClick={() => this.handleAnswerQuestion(true)}
                disabled={isLoading}>
                Somewhat True
              </button>
              <button
                className="button is-link eval-button"
                onClick={() => this.handleAnswerQuestion(false)}
                disabled={isLoading}>
                Somewhat False
              </button>
              <button
                className="button is-link eval-button"
                onClick={() => this.handleAnswerQuestion(false)}
                disabled={isLoading}>
                False
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: { questions: QuestionsState; evaluations: EvaluationState }) {
  const { questions, evaluations } = state;
  return { questions, evaluations };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators({ pushLoading, popLoading, setActiveEvaluation, fireToast }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Evaluation);
