import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Question = {
  _id: string;
  question: string;
  section: string;
};

export type QuestionsState = Question[];

const initialState: QuestionsState = [];

export const questionSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setQuestions: (state, action: PayloadAction<Question[]>) => [...action.payload]
  }
});

export const { setQuestions } = questionSlice.actions;

export default questionSlice.reducer;
