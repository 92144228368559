import { faCartShopping, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnyAction, bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { Component } from 'react';
import { setTokens, TokenState } from '../features/tokens/tokenSlice';
import { connect } from 'react-redux';
import req from '../Axios';
import { popLoading, pushLoading } from '../features/loading/loadingSlice';
import { fireToast } from '../features/toast/toastSlice';
import { PartialEvaluation } from '../features/evaluations/evaluationSlice';
import { Product } from '../types';
import { loadStripe } from '@stripe/stripe-js';

type Props = {
  id: string | undefined;
  isActive: boolean;
  handleCancelClick: () => void;
  tokens: TokenState;
  setTokens: typeof setTokens;
  pushLoading: typeof pushLoading;
  popLoading: typeof popLoading;
  fireToast: typeof fireToast;
};

type State = {
  navigateToEvaluation: boolean;
  evaluationName: string;
  partial: PartialEvaluation | undefined;
  products: Product[];
  productIndex: number;
};

class StoreModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      navigateToEvaluation: false,
      evaluationName: '',
      partial: undefined,
      products: [],
      productIndex: 0
    };
  }

  componentDidMount = async () => {
    this.props.pushLoading('store');

    const response = await req.get('/purchases/products');

    this.setState({ products: response.data, productIndex: 0 }, () =>
      this.props.popLoading('store')
    );
  };

  handleCheckoutClick = async () => {
    try {
      this.props.pushLoading('checkout');

      const { products, productIndex } = this.state;
      const selectedProduct = products[productIndex];

      // request session from API
      const response = await req.post('/purchases/checkout', {
        productName: selectedProduct.name
      });

      const { session } = response.data;

      const key: string = process.env.REACT_APP_STRIPE_PUBLISHER_KEY
        ? process.env.REACT_APP_STRIPE_PUBLISHER_KEY
        : '';

      console.log(key);

      // redirect to stripe session URL, response.data.session...
      const stripe = await loadStripe(key);

      const result = await stripe?.redirectToCheckout({
        sessionId: session.id
      });

      if (result && result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      }
    } catch (e) {
      this.props.popLoading('checkout');
      if (typeof e === 'string') this.props.fireToast({ message: e, failure: true });
      else
        this.props.fireToast({
          message:
            "We don't know what went wrong, but our checkout isn't working right now. Please try again later.",
          failure: true
        });
    }
  };

  handleCloseModal = () => {
    this.setState({
      evaluationName: '',
      partial: undefined,
      navigateToEvaluation: false
    });

    this.props.handleCancelClick();
  };

  handleInputChange = (e: any) => {
    const { name, value } = e.target;
    const newState = { [name]: value } as Pick<State, keyof State>;
    this.setState(newState);
  };

  handleIncrementProduct = () => {
    const { products, productIndex } = this.state;
    if (products.length - 1 === productIndex) return;

    this.setState((state) => ({
      productIndex: state.productIndex + 1
    }));
  };

  handleDecrementProduct = () => {
    const { productIndex } = this.state;
    if (productIndex === 0) return;

    this.setState((state) => ({
      productIndex: state.productIndex - 1
    }));
  };

  render() {
    let modalClasses = 'modal';

    if (this.props.isActive) modalClasses = 'modal is-active';

    let selectedProduct = {
      name: '',
      description: '',
      quantity: 0,
      price: 0.0
    };

    const { products, productIndex } = this.state;

    if (products.length > 0) selectedProduct = products[productIndex];

    return (
      <div className={modalClasses}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Shop UniqueSelf</p>
            <button className="delete" aria-label="close" onClick={this.handleCloseModal}></button>
          </header>
          <section className="modal-card-body">
            <p className="has-text-centered">How many tests would you like to purchase?</p>
            <div className="purchase-flexbox">
              <div>
                <button
                  className="button is-ghost plus-minus-button"
                  onClick={this.handleDecrementProduct}>
                  <FontAwesomeIcon icon={faMinus}></FontAwesomeIcon>
                </button>
              </div>
              <div className="purchase-count">{selectedProduct.quantity}</div>
              <div>
                <button
                  className="button is-ghost plus-minus-button"
                  onClick={this.handleIncrementProduct}>
                  <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                </button>
              </div>
            </div>
            <div className="has-text-centered negative-margin-half purchase-dollar">
              at ${selectedProduct.price}
            </div>
            <p className="mt-5">
              Upon clicking checkout, you&apos;ll be directed to our payment processor Stripe to
              collect payment. When your payment has been successfully processed, we&apos;ll see you
              back here.
            </p>
          </section>
          <footer className="modal-card-foot">
            <div className="is-flex-grow-1"></div>
            <button className="button is-outlined is-link" onClick={this.handleCloseModal}>
              Cancel
            </button>
            <button className="button is-link" onClick={this.handleCheckoutClick}>
              Checkout
              <FontAwesomeIcon icon={faCartShopping} className="ml-2"></FontAwesomeIcon>
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: { tokens: TokenState }) {
  const { tokens } = state;
  return { tokens };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators({ setTokens, pushLoading, popLoading, fireToast }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreModal);
