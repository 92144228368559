import { faCopy, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClipboardJS from 'clipboard';
import { Component } from 'react';

type Props = {
  id: string | undefined;
  isActive: boolean;
  email: string | undefined;
  evaluationId: string | undefined;
  handleCancelClick: () => void;
};

type State = {
  isCopied: boolean;
};

class ShareModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isCopied: false
    };
  }
  componentDidMount() {
    new ClipboardJS('.clipboard-btn');
  }

  handleCopyClick = () => {
    this.setState({ isCopied: true });
  };

  handleCloseModal = () => {
    this.setState({ isCopied: false });
    this.props.handleCancelClick();
  };

  render() {
    let modalClasses = 'modal';

    if (this.props.isActive) modalClasses = 'modal is-active';

    const { email, evaluationId } = this.props;

    const shareLink = `https://uniqueselfapi.abidinglife.com/results?email=${email}&ust=${evaluationId}`;

    let copied = '';

    if (this.state.isCopied) copied = 'Copied!';

    return (
      <div className={modalClasses}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Share Results</p>
            <button className="delete" aria-label="close" onClick={this.handleCloseModal}></button>
          </header>
          <section className="modal-card-body">
            <div className="mb-3">
              Here is your shared test link. Anyone with this link can access your results. Copy it
              from here and send it to whoever you would like.
            </div>
            <div className="field has-addons">
              <div className="control w-100">
                <input
                  className="input"
                  type="text"
                  value={shareLink}
                  placeholder="Where should we send these results?"
                  disabled
                />
              </div>
              <div className="control">
                <div
                  className="button is-link clipboard-btn"
                  data-clipboard-text={shareLink}
                  onClick={this.handleCopyClick}>
                  <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
                </div>
              </div>
            </div>
            <div className="is-flex is-justify-content-flex-end">
              <p className="is-size-7">&nbsp;{copied}</p>
            </div>
          </section>
          <footer className="modal-card-foot is-justify-content-flex-end">
            <button className="button is-link is-outlined" onClick={this.handleCloseModal}>
              Got It
              <FontAwesomeIcon icon={faThumbsUp} className="ml-2"></FontAwesomeIcon>
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
export default ShareModal;
