import { AnyAction, Dispatch, bindActionCreators } from '@reduxjs/toolkit';
import { Component } from 'react';
import { connect } from 'react-redux';
import Navigation from '../components/Navigation';
import { popLoading, pushLoading } from '../features/loading/loadingSlice';
import { QuestionsState } from '../features/questions/questionSlice';
import req from '../Axios';
import { fireToast } from '../features/toast/toastSlice';
import { Product } from '../types';
import { IdentityState } from '../features/identity/identitySlice';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

interface Props {
  pushLoading: typeof pushLoading;
  popLoading: typeof popLoading;
  fireToast: typeof fireToast;
  identity: IdentityState;
}

interface State {
  products: Product[];
  product: Product | undefined;
}

class CheckoutSuccess extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      products: [],
      product: undefined
    };
  }

  async componentDidMount() {
    this.props.pushLoading('checkout-success');

    const response = await req.get('/purchases/products');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlParamQuantity: string | null = urlParams.get('quantity');
    let quantity = 0;
    if (urlParamQuantity) quantity = parseInt(urlParamQuantity);

    this.setState(
      {
        products: response.data,
        product: response.data.find((p: Product) => p.quantity === quantity)
      },
      () => this.props.popLoading('checkout-success')
    );
  }

  render() {
    return (
      <div>
        <div className="section">
          <div className="content">
            <div className="box eval-box">
              <div className="has-text-centered icon-header success">
                <FontAwesomeIcon icon={faCircleCheck}></FontAwesomeIcon>
              </div>
              <p>
                Your purchase of <b>{this.state.product?.quantity} UniqueSelf test(s)</b> was
                successful. Please allow up to 15 minutes for the tests to appear in your account.
              </p>
              <div className="has-text-centered">
                <Link to="/login" className="button is-ghost">
                  Return to My Dashboard
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: { identity: IdentityState }) {
  const { identity } = state;
  return { identity };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators({ pushLoading, popLoading, fireToast }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSuccess);
