import Axios, { AxiosError } from 'axios';
import { store } from './app/store';
import {
  clearIdentity,
  evaluateTokenRefresh,
  refreshToken
} from './features/identity/identitySlice';

const instance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

// Request interceptors
instance.interceptors.request.use(
  async (config) => {
    const state = store.getState();
    const { dispatch } = store;
    // check for existing token, indicating authenticated user
    if (state.identity.access_token) {
      // ensure token is valid and refreshed
      await dispatch(evaluateTokenRefresh() as any);
      // set auth headers for request
      // update state in event token refreshed
      const updatedState = store.getState();
      const { access_token } = updatedState.identity;
      if (config.headers) config.headers.Authorization = 'Bearer ' + access_token;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptors
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { dispatch } = store;

    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      originalRequest.url === `${process.env.REACT_APP_API_URL}/identity/refresh`
    ) {
      // need to add logout
      return Promise.reject(error);
    }

    // 401 without a retry, then refresh token and retry
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await dispatch(refreshToken() as any);

        const state = store.getState();
        const { access_token } = state.identity;

        // Axios.defaults.headers.common.Authorization = 'Bearer ' + access_token;
        originalRequest.headers.Authorization = 'Bearer ' + access_token;
        console.log('originalRequest', originalRequest);

        if (error) throw error;

        return Axios(originalRequest);
      } catch (e: any) {
        if (e.message == 'Request failed with status code 401' && originalRequest._retry)
          dispatch(clearIdentity() as any);
        console.log('axios error', e);
        return Promise.reject(e);
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
