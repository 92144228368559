import { Component, createRef } from 'react';
import { Result, Score } from '../features/evaluations/evaluationSlice';
import { titleCase, toSentenceCase } from '../helpers';
import { Blend, Video } from '../types';
import parse from 'html-react-parser';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  result: Result;
  blends: Blend[];
};

type State = {
  activeVideoIndex: number;
  videos: Video[];
  alreadyScrolled: boolean;
};

class ResultCard extends Component<Props, State> {
  private videoRef: React.RefObject<HTMLVideoElement> | undefined;
  private availableVideos;
  constructor(props: Props) {
    super(props);
    this.state = {
      activeVideoIndex: 0,
      alreadyScrolled: false,
      videos: [
        {
          category: 'affection',
          attribute: 'thinker',
          source:
            'https://uniqueself-videos.s3.us-west-2.amazonaws.com/low-definition/Affection+Thinker.mp4'
        },
        {
          category: 'affection',
          attribute: 'feeler',
          source:
            'https://uniqueself-videos.s3.us-west-2.amazonaws.com/low-definition/Affection+Feeler.mp4'
        }
      ]
    };

    this.videoRef = createRef();

    this.availableVideos = [
      {
        category: 'affection',
        attribute: 'thinker',
        source:
          'https://uniqueself-videos.s3.us-west-2.amazonaws.com/low-definition/Affection+Thinker.mp4'
      },
      {
        category: 'affection',
        attribute: 'feeler',
        source:
          'https://uniqueself-videos.s3.us-west-2.amazonaws.com/low-definition/Affection+Feeler.mp4'
      },
      {
        category: 'affection',
        attribute: 'doer',
        source:
          'https://uniqueself-videos.s3.us-west-2.amazonaws.com/low-definition/Affection+Doer.mp4'
      },
      {
        category: 'stress',
        attribute: 'thinker',
        source:
          'https://uniqueself-videos.s3.us-west-2.amazonaws.com/low-definition/Stress+Thinker.mp4'
      },
      {
        category: 'stress',
        attribute: 'doer',
        source:
          'https://uniqueself-videos.s3.us-west-2.amazonaws.com/low-definition/Stress+Doer.mp4'
      },
      {
        category: 'stress',
        attribute: 'feeler',
        source:
          'https://uniqueself-videos.s3.us-west-2.amazonaws.com/low-definition/Stress+Feeler.mp4'
      },
      {
        category: 'social',
        attribute: 'thinker',
        source:
          'https://uniqueself-videos.s3.us-west-2.amazonaws.com/low-definition/Social+Thinker.mp4'
      },
      {
        category: 'social',
        attribute: 'feeler',
        source:
          'https://uniqueself-videos.s3.us-west-2.amazonaws.com/low-definition/Social+Feeler.mp4'
      },
      {
        category: 'social',
        attribute: 'doer',
        source:
          'https://uniqueself-videos.s3.us-west-2.amazonaws.com/low-definition/Social+Doer.mp4'
      }
    ];
  }

  componentDidMount() {
    const { availableVideos } = this;
    const { result } = this.props;
    const { scores } = result;

    const video1 = availableVideos.filter(
      (v) => v.attribute == scores[0].attribute && v.category == result.category
    );

    const video2 = availableVideos.filter(
      (v) => v.attribute == scores[1].attribute && v.category == result.category
    );

    const videos = [...video1, ...video2];

    this.setState({
      videos
    });
  }

  scrollToTop = () => {
    if (this.state.alreadyScrolled) return;

    window.scrollTo(0, 0);
    this.setState({ alreadyScrolled: true });
  };

  handleChangeVideoClick = (index: number) => {
    this.setState(
      (state) => ({
        activeVideoIndex: state.activeVideoIndex + index
      }),
      () => this.videoRef?.current?.load()
    );
  };

  progressBar = (attribute: string, percent: number) => {
    return (
      <div className="mb-2">
        <div className="is-size-7 is-flex is-justify-content-space-between w-100">
          <div>{toSentenceCase(attribute)}</div>
          <div>{`${Math.round(percent)}%`}</div>
        </div>
        <div className="progress-container">
          <div style={{ width: `${percent}%` }} className={`progress ${attribute}`} />
        </div>
      </div>
    );
  };

  progressBars = (scores?: Score[]) => {
    const bars: any[] = [];

    if (!scores) return;

    scores.forEach((s) => {
      bars.push(this.progressBar(s.attribute, s.scorePercentage * 100));
    });

    return bars;
  };

  render() {
    const { result } = this.props;
    let blend = this.props.blends.find((b) => b.title == result.blend);

    if (!blend)
      blend = {
        title: '',
        attributes: '',
        description: '',
        biblicalType: ''
      };

    // const id = `${result.category}-chart`;
    const data = result?.scores;
    // const labels = result?.scores.map((s) => titleCase(s.attribute));

    const video = this.state.videos[this.state.activeVideoIndex];

    return (
      <div className="section">
        <div className="container">
          <h1>
            {titleCase(result.category)} {result.blend}
          </h1>
          <h2 className="mb-4">{result.attributes}</h2>
          <div className="box">
            <div className="columns">
              <div className="column is-1 is-justify-content-center is-align-items-center is-flex">
                {this.state.activeVideoIndex > 0 && (
                  <button
                    className="button is-ghost video-chevron"
                    onClick={() => this.handleChangeVideoClick(-1)}>
                    <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
                  </button>
                )}
              </div>
              <div className="column">
                <div className="video-container">
                  <video
                    height="625"
                    controls
                    className="video-player"
                    ref={this.videoRef}
                    onLoadedData={this.scrollToTop}>
                    <source src={video.source} type="video/mp4" />
                  </video>
                  <div className="w-100 has-text-centered is-size-5">
                    {titleCase(video.category)} {titleCase(video.attribute)}
                  </div>
                </div>
              </div>
              <div className="column is-1 is-justify-content-center is-align-items-center is-flex">
                {this.state.activeVideoIndex == 0 && (
                  <button
                    className="button is-ghost video-chevron"
                    onClick={() => this.handleChangeVideoClick(1)}>
                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="box ">
            <h3>{result.blend}</h3>

            <div className="blend-box">
              <div className="floated-chart w-33">
                <div className="bounding-box">{this.progressBars(data)}</div>
              </div>

              {/* <DoughnutChart id={id} data={data} labels={labels} className="floated-chart" /> */}
              {parse(blend.description)}
              <p>
                <span className="bold">Biblical Character: </span>
                {blend.biblicalType}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResultCard;
