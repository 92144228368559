import { faGift, faStore } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnyAction, bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { Component } from 'react';
import { setTokens, TokenState } from '../features/tokens/tokenSlice';
import { connect } from 'react-redux';
import req from '../Axios';
import { popLoading, pushLoading } from '../features/loading/loadingSlice';
import { fireToast } from '../features/toast/toastSlice';

type Props = {
  id: string | undefined;
  isActive: boolean;
  handleCancelClick: () => void;
  handleOpenStoreModal: () => void;
  tokens: TokenState;
  setTokens: typeof setTokens;
  pushLoading: typeof pushLoading;
  popLoading: typeof popLoading;
  fireToast: typeof fireToast;
};

type State = {
  email: string;
  disabled: boolean;
};

class SendGiftModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      email: '',
      disabled: true
    };
  }

  componentDidMount = async () => {
    await this.refreshTokens();
  };

  refreshTokens = async () => {
    try {
      const response = await req.get('/tokens');

      this.props.setTokens(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  handleSendTestClick = async () => {
    try {
      const payload = {
        grantToEmail: this.state.email
      };

      if (!this.state.email)
        this.props.fireToast({
          message: 'Please enter a valid email address and try again.',
          failure: true
        });

      if (this.props.tokens.length === 0)
        this.props.fireToast({
          message: 'You do not have any tests to gift. Please purchase more and try again.',
          failure: true
        });

      const tokenToGift = this.props.tokens[0]._id;

      await req.patch(`/tokens/${tokenToGift}`, payload);

      await this.refreshTokens();

      this.props.fireToast({
        message: `A test has been successfully sent to ${this.state.email}.`,
        failure: false
      });

      this.handleCloseModal();
    } catch (e) {
      this.props.fireToast({
        message: 'We cannot figure out what went wrong. Please wait 15 minutes and then try again.',
        failure: true
      });
    }
  };

  handleOpenStoreModal = () => {
    this.handleCloseModal();
    this.props.handleOpenStoreModal();
  };

  handleCloseModal = () => {
    this.setState({
      email: '',
      disabled: false
    });

    this.props.handleCancelClick();
  };

  handleInputChange = (e: any) => {
    this.setState({
      email: e.target.value,
      disabled: e.target.value ? false : true
    });
  };

  render() {
    let modalClasses = 'modal';

    if (this.props.isActive) modalClasses = 'modal is-active';

    return (
      <div className={modalClasses}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Let&apos;s Send a Gift</p>
            <button className="delete" aria-label="close" onClick={this.handleCloseModal}></button>
          </header>
          <section className="modal-card-body">
            {this.props.tokens.length === 0 && (
              <div>
                <p className="mt-5 is-size-6">
                  It looks like you don&apos;t have any tests to gift. Please purchase more in the
                  store and come back here to send some out.
                </p>
                <div className="has-text-centered">
                  <button className="button is-ghost mt-5" onClick={this.handleOpenStoreModal}>
                    Buy More Tests
                    <FontAwesomeIcon icon={faStore} className="ml-2"></FontAwesomeIcon>
                  </button>
                </div>
              </div>
            )}
            {this.props.tokens.length > 0 && (
              <div>
                <p className="mb-5">You have {this.props.tokens.length} tests available to send.</p>
                <div className="field">
                  <label className="label">Recipient Email Address</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      value={this.state.email}
                      name="email"
                      onChange={this.handleInputChange}
                      placeholder="Where should we send this gift?"
                      disabled={this.props.tokens.length === 0}
                    />
                  </div>
                </div>
                <p className="mt-5 is-size-6">
                  Clicking send will immediately deliver <b>1 UniqueSelf Test</b> to{' '}
                  <b>{this.state.email ? this.state.email : 'Enter an Email Address Above'}</b>. You
                  cannot reverse this action. The user will only be able to redeem this test with an
                  account under the email address you provide. They do not already need to have an
                  account, they can make one at any time and the test you send will be there waiting
                  for them.
                </p>
              </div>
            )}
          </section>
          <footer className="modal-card-foot">
            <div className="is-flex-grow-1"></div>
            <button className="button is-outlined is-link" onClick={this.handleCloseModal}>
              Cancel
            </button>
            <button
              className="button is-link"
              onClick={this.handleSendTestClick}
              disabled={this.state.disabled || this.props.tokens.length === 0}>
              Send
              <FontAwesomeIcon icon={faGift} className="ml-2"></FontAwesomeIcon>
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: { tokens: TokenState }) {
  const { tokens } = state;
  return { tokens };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators({ setTokens, pushLoading, popLoading, fireToast }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SendGiftModal);
