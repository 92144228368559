import { AnyAction, Dispatch, bindActionCreators } from '@reduxjs/toolkit';
import { Component } from 'react';
import { connect } from 'react-redux';
import { popLoading, pushLoading } from '../features/loading/loadingSlice';
import { fireToast } from '../features/toast/toastSlice';
import { Product } from '../types';
import { IdentityState } from '../features/identity/identitySlice';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

interface Props {
  pushLoading: typeof pushLoading;
  popLoading: typeof popLoading;
  fireToast: typeof fireToast;
  identity: IdentityState;
}

interface State {
  products: Product[];
  product: Product | undefined;
}

class CheckoutFailed extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      products: [],
      product: undefined
    };
  }

  render() {
    return (
      <div>
        <div className="section">
          <div className="content">
            <div className="box eval-box">
              <div className="has-text-centered icon-header failed">
                <FontAwesomeIcon icon={faXmarkCircle}></FontAwesomeIcon>
              </div>
              <p>
                Your purchase was canceled or failed. Please check your payment details and try
                again.
              </p>
              <div className="has-text-centered">
                <Link to="/login" className="button is-ghost">
                  Return to My Dashboard
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: { identity: IdentityState }) {
  const { identity } = state;
  return { identity };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators({ pushLoading, popLoading, fireToast }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutFailed);
