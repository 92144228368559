import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEye, faLock } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { IdentityState, setIdentity } from '../features/identity/identitySlice';
import { Link, Navigate } from 'react-router-dom';
import { popLoading, pushLoading } from '../features/loading/loadingSlice';
import { fireToast } from '../features/toast/toastSlice';
import { validateEmail } from '../helpers';

type Props = {
  setIdentity: typeof setIdentity;
  pushLoading: typeof pushLoading;
  popLoading: typeof popLoading;
  identity: IdentityState;
  fireToast: typeof fireToast;
};

type State = {
  username: string;
  password: string;
  showPassword: boolean;
};

class Login extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      showPassword: false
    };
  }

  handleLogin = async () => {
    const url = process.env.REACT_APP_API_URL;
    try {
      this.props.pushLoading('login');

      if (!this.state.username || !this.state.password) {
        throw 'Email and password are required.';
      }

      if (!validateEmail(this.state.username)) {
        throw 'Please enter a valid email address.';
      }

      const response = await axios.post(`${url}/identity/login`, {
        email: this.state.username,
        password: this.state.password
      });

      this.props.setIdentity(response.data);

      // persist identity to session storage
      const identity = JSON.stringify(response.data);
      sessionStorage.setItem('identity', identity);
      this.props.popLoading('login');
    } catch (e: any) {
      this.props.popLoading('login');

      if (e?.response?.data?.message === 'Bad Request')
        this.props.fireToast({
          message: 'The email address you entered already has an account.',
          failure: true
        });
      else if (typeof e?.response?.data?.message === 'string')
        this.props.fireToast({
          message: e.response.data.message,
          failure: true
        });
      else if (typeof e === 'string')
        this.props.fireToast({
          message: e,
          failure: true
        });
      else
        this.props.fireToast({
          message:
            "We don't know exactly what went wrong. Please check your credentials and try again.",
          failure: true
        });
    }
  };

  handleChange = (e: any) => {
    const { name, value } = e.target;
    const newState = { [name]: value } as Pick<State, keyof State>;
    this.setState(newState);
  };

  toggleShowPassword = () => {
    this.setState((state) => ({
      showPassword: !state.showPassword
    }));
  };

  render() {
    return (
      <div className="full-height-container">
        {this.props.identity.access_token && <Navigate to="/dashboard" replace={true} />}
        <div className="vertical-centered-content">
          <header className="login-header">
            <div className="logo-lg"></div>
          </header>

          <div className="card">
            <header className="card-header">
              <h1 className="card-header-title">Login</h1>
            </header>
            <div className="card-content">
              <div className="content">
                <div className="mb-3">
                  We&apos;re glad you&apos;re here. Let&apos;s get started.
                </div>

                <div className="field">
                  <p className="control has-icons-left has-icons-right">
                    <input
                      className="input"
                      type="email"
                      name="username"
                      placeholder="Email"
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                  </p>
                </div>
                <div className="field has-addons">
                  <div className="control has-icons-left w-100">
                    <input
                      className="input"
                      type={this.state.showPassword ? 'text' : 'password'}
                      placeholder="Password"
                      name="password"
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <FontAwesomeIcon icon={faLock} />
                    </span>
                  </div>
                  <div className="control">
                    <a className="button is-primary" onClick={this.toggleShowPassword}>
                      <FontAwesomeIcon icon={faEye} />
                    </a>
                  </div>
                </div>
                <div className="field is-grouped is-grouped-left">
                  <p className="control ">
                    <button className="button is-primary" onClick={this.handleLogin}>
                      Login
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <footer className="login-footer">
            <Link to="/register" className="is-link">
              New User? Create an Account
            </Link>
            <Link to="/forgot" className="is-link">
              Forgot Password
            </Link>
          </footer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: { identity: IdentityState }) {
  const { identity } = state;
  return { identity };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators({ setIdentity, pushLoading, popLoading, fireToast }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
