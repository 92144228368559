import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TokenState = Token[];

export type Token = {
  _id: string;
  purchaseId: string;
  grantedToEmail: string;
  createdAt: Date;
};

const initialState: TokenState = [];

export const tokenSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    setTokens: (state, action: PayloadAction<Token[]>) => [...action.payload]
  }
});

export const { setTokens } = tokenSlice.actions;

export default tokenSlice.reducer;
