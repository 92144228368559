import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface ToastState {
  failure: boolean;
  message?: string;
}

const initialState: ToastState = {
  failure: true,
  message: undefined
};

export const toastSlice = createSlice({
  name: 'identity',
  initialState,
  reducers: {
    fireToast: (state, action: PayloadAction<ToastState>) => ({
      ...state,
      ...action.payload
    }),
    hideToast: () => ({
      ...initialState
    })
  }
});

export const { fireToast, hideToast } = toastSlice.actions;

export const isDisplayed = (state: RootState) => !!state.toast.message;
export const selectToastMessage = (state: RootState) => state.toast.message;
export const selectToastFailure = (state: RootState) => state.toast.failure;

export default toastSlice.reducer;
