import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import identityReducer from '../features/identity/identitySlice';
import evaluationReducer from '../features/evaluations/evaluationSlice';
import questionReducer from '../features/questions/questionSlice';
import tokenReducer from '../features/tokens/tokenSlice';
import loadingReducer from '../features/loading/loadingSlice';
import toastReducer from '../features/toast/toastSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    identity: identityReducer,
    evaluations: evaluationReducer,
    questions: questionReducer,
    tokens: tokenReducer,
    loading: loadingReducer,
    toast: toastReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
