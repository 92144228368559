import React, { useState } from 'react';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { hideToast, isDisplayed, selectToastFailure, selectToastMessage } from './toastSlice';

export function Toast() {
  const showToast = useAppSelector(isDisplayed);
  const message = useAppSelector(selectToastMessage);
  const failure = useAppSelector(selectToastFailure);
  const dispatch = useAppDispatch();

  let alertClasses = 'message is-danger';
  if (!failure) alertClasses = 'message is-success';

  let title = 'Something Went Wrong';
  if (!failure) title = 'Success!';

  const content = (
    <div className="toast-container">
      <article className={alertClasses}>
        <div className="message-header">
          <p>{title}</p>
          <button
            className="delete"
            aria-label="delete"
            onClick={() => dispatch(hideToast())}></button>
        </div>
        <div className="message-body">{message}</div>
      </article>
    </div>
  );

  return showToast ? content : null;
  //   return content;
}
