import { Component, ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import { IdentityState, setIdentity } from './features/identity/identitySlice';
import Dashboard from './pages/Dashboard';
import Learn from './pages/Learn';
import Login from './pages/Login';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Evaluation from './pages/Evaluation';
import Loading from './components/Loading';
import { LoadingState, selectIsLoading } from './features/loading/loadingSlice';
import { Toast } from './features/toast/Toast';
import CheckoutSuccess from './pages/CheckoutSuccess';
import CheckoutFailed from './pages/CheckoutFailed';
import ForgotPassword from './pages/ForgotPassword';
import Register from './pages/Register';

type Props = {
  setIdentity: typeof setIdentity;
  isLoading: boolean;
};
class App extends Component<Props> {
  componentDidMount() {
    const identitySession = sessionStorage.getItem('identity');

    if (identitySession) {
      const identity = JSON.parse(identitySession);
      this.props.setIdentity(identity);
    }
  }

  render(): ReactNode {
    return (
      <div>
        {this.props.isLoading && <Loading />}
        <Toast />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/learn/:id" element={<Learn />} />
          <Route path="/evaluation" element={<Evaluation />} />
          <Route path="/checkout-successful" element={<CheckoutSuccess />} />
          <Route path="/checkout-failed" element={<CheckoutFailed />} />
        </Routes>
      </div>
    );
  }
}

function mapStateToProps(state: { identity: IdentityState; loading: LoadingState }) {
  const { identity, loading } = state;
  const isLoading = selectIsLoading(loading);
  return { identity, isLoading };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators({ setIdentity }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
