import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type LoadingState = string[];

const initialState: LoadingState = [];

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    pushLoading: (state, action: PayloadAction<string>) => [...state, action.payload],
    popLoading: (state, action: PayloadAction<string>) => {
      const loading = state.filter((s) => s !== action.payload);
      return loading;
    }
  }
});

export const selectIsLoading = (state: LoadingState) => state.length > 0;

export const { pushLoading } = loadingSlice.actions;
export const { popLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
