import { Component } from 'react';
import { connect } from 'react-redux';
import req from '../Axios';
import Navigation from '../components/Navigation';
import {
  Evaluation,
  EvaluationState,
  setCompleteEvaluations,
  setPartialEvaluations,
  setActiveEvaluation,
  Score
} from '../features/evaluations/evaluationSlice';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleQuestion,
  faInfoCircle,
  faPenClip,
  faPlus,
  faShare
} from '@fortawesome/free-solid-svg-icons';
import { DateTime } from 'luxon';
import { QuestionsState, setQuestions } from '../features/questions/questionSlice';
import { toSentenceCase } from '../helpers';
import ShareModal from '../components/ShareModal';
import { IdentityState } from '../features/identity/identitySlice';
import { Link, Navigate } from 'react-router-dom';
import { setTokens } from '../features/tokens/tokenSlice';
import { popLoading, pushLoading } from '../features/loading/loadingSlice';

type Props = {
  setCompleteEvaluations: typeof setCompleteEvaluations;
  setPartialEvaluations: typeof setPartialEvaluations;
  setActiveEvaluation: typeof setActiveEvaluation;
  setQuestions: typeof setQuestions;
  setTokens: typeof setTokens;
  pushLoading: typeof pushLoading;
  popLoading: typeof popLoading;
  evaluations: EvaluationState;
  questions: QuestionsState;
  identity: IdentityState;
};

type State = {
  sharingEvaluationId: string | undefined;
  learningId: string | undefined;
};

class Dashboard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      sharingEvaluationId: undefined,
      learningId: undefined
    };
  }

  async componentDidMount() {
    try {
      this.props.pushLoading('dashboard');
      this.props.popLoading('login');
      const questions = await req.get('/questions');
      this.props.setQuestions(questions.data);

      const evaluations = await req.get('/evaluations');
      this.props.setCompleteEvaluations(evaluations.data);

      const partials = await req.get('/partials');
      this.props.setPartialEvaluations(partials.data);

      const tokens = await req.get('/tokens');
      this.props.setTokens(tokens.data);
      this.props.popLoading('dashboard');
      this.props.popLoading('blends');
    } catch (e) {
      this.props.popLoading('dashboard');
      this.props.popLoading('blends');
      console.log(e);
    }
  }

  handleShareClick = (e: any) => {
    this.setState({
      sharingEvaluationId: e.target.id
    });
  };

  handleCancelShareClick = () => {
    this.setState({
      sharingEvaluationId: undefined
    });
  };

  handleLearnMoreClick = (evaluation: Evaluation) => {
    this.setState({
      learningId: evaluation._id
    });
    this.props.setActiveEvaluation(evaluation);
  };

  progressBar = (attribute: string, percent: number) => {
    return (
      <div className="mb-1">
        <div className="is-size-7 is-flex is-justify-content-space-between w-100">
          <div>{toSentenceCase(attribute)}</div>
          <div>{`${Math.round(percent)}%`}</div>
        </div>
        <div className="progress-container">
          <div style={{ width: `${percent}%` }} className={`progress ${attribute}`} />
        </div>
      </div>
    );
  };

  progressBars = (scores?: Score[]) => {
    const bars: any[] = [];

    if (!scores) return;

    scores.forEach((s) => {
      bars.push(this.progressBar(s.attribute, s.scorePercentage * 100));
    });

    return bars;
  };

  getCompletedEvaluations = () => {
    const complete = this.props.evaluations.complete.map((c) => {
      const results = c.results.map((r) => {
        let icon;
        if (r.category == 'social')
          icon = <img src={require('../assets/images/social.png')} className="category-icons" />;
        if (r.category == 'stress')
          icon = <img src={require('../assets/images/stress.png')} className="category-icons" />;
        if (r.category == 'affection')
          icon = <img src={require('../assets/images/affection.png')} className="category-icons" />;

        const data = c.results
          .find((result) => result.category === r.category)
          ?.scores.map((s) => s);

        return (
          <div key={r.category} className="column is-one-third px-5">
            <div className="is-flex is-flex-direction-column">
              <div className="is-flex is-justify-content-start is-align-items-center mb-1">
                <div>{icon}</div>
                <div className="is-flex is-flex-direction-column">
                  <span className="bold is-capitalized">
                    {r.category} {r.blend}
                  </span>
                  {r.attributes}
                </div>
              </div>
              {this.progressBars(data)}
            </div>
          </div>
        );
      });

      const dt = DateTime.fromISO(c.createdAt);

      return (
        <div className="column is-full" key={c._id}>
          <div className="card h-100 is-flex is-flex-direction-column">
            <header className="card-header">
              <div className="is-flex is-align-items-center is-flex-grow-1 is-justify-content-space-between">
                <div className="card-header-title is-flex-direction-column is-align-items-flex-start">
                  <div>{c.name}</div>
                  <div className="card-subtitle">
                    <time dateTime={c.createdAt}>{dt.toLocaleString(DateTime.DATETIME_MED)}</time>
                  </div>
                </div>
                <div className="buttons pr-4">
                  <div id={c._id} className="button is-outlined" onClick={this.handleShareClick}>
                    Share
                    <FontAwesomeIcon icon={faShare} className="ml-2"></FontAwesomeIcon>
                  </div>
                  <div className="button is-link" onClick={() => this.handleLearnMoreClick(c)}>
                    Learn More
                    <FontAwesomeIcon icon={faCircleQuestion} className="ml-2"></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            </header>
            <div className="card-content">
              <div className="columns is-multiline">{results}</div>
            </div>
            <footer className="card-footer mt-3"></footer>
          </div>
        </div>
      );
    });

    return complete;
  };

  getPartialEvaluations = () => {
    const partials = this.props.evaluations.partial.map((p) => {
      const dt = DateTime.fromISO(p.createdAt);

      let nextQuestion = 'Unable to determine your next question.';

      if (this.props.questions.length > 0) {
        if (p.answers.length == this.props.questions.length) nextQuestion = 'All done!';
        else nextQuestion = this.props.questions[p.answers.length].question;
      }

      return (
        <div className="column is-one-third" key={p._id}>
          <div className="card h-100 is-flex is-flex-direction-column">
            <header className="card-header">
              <div className="card-header-title is-flex-direction-column is-align-items-flex-start">
                <div>{p.name}</div>
                <div className="card-subtitle">
                  <time dateTime={p.createdAt}>{dt.toLocaleString(DateTime.DATETIME_MED)}</time>
                </div>
              </div>
            </header>
            <div className="card-content">
              <div className="content">
                <div>Up Next</div>
                <div className="bold">{nextQuestion.toUpperCase()}</div>
              </div>
            </div>
            <div className="is-flex-grow-1"></div>
            <footer className="card-footer">
              <Link to={`/evaluation?partial=${p._id}`} className="card-footer-item">
                Continue
                <FontAwesomeIcon icon={faPenClip} className="ml-2"></FontAwesomeIcon>
              </Link>
            </footer>
          </div>
        </div>
      );
    });

    return partials;
  };

  render() {
    const { learningId } = this.state;

    const { active, complete, partial } = this.props.evaluations;

    const nothingYet = complete.length === 0 && partial.length === 0;

    return (
      <div>
        {!!learningId && <Navigate to={`/learn/${active?._id}`} replace={false} />}
        <Navigation />
        <div className="section">
          <div className="container">
            {partial.length > 0 && (
              <div id="in-progress" className="content mb-6">
                <h1>In Progress</h1>
                <div className="is-flex is-justify-content-flex-start is-align-items-center mt-1 mb-4">
                  <FontAwesomeIcon icon={faInfoCircle} className="ml-1 mr-1"></FontAwesomeIcon>
                  <div className="ml-2">
                    Click <span className="bold">Continue</span> to pickup where you left off.
                  </div>
                </div>
                <div className="columns is-multiline">{this.getPartialEvaluations()}</div>
              </div>
            )}

            {complete.length > 0 && (
              <div id="completed" className="content">
                <h1>My Completed Tests</h1>
                <div className="is-flex is-justify-content-flex-start is-align-items-center mt-1 mb-4">
                  <FontAwesomeIcon icon={faInfoCircle} className="ml-1 mr-1"></FontAwesomeIcon>
                  <div className="ml-2">
                    Click <span className="bold">Learn More </span>
                    to dive deeper into your results and learn about your UniqueSelf.
                  </div>
                </div>
                <div className="columns is-multiline">{this.getCompletedEvaluations()}</div>
              </div>
            )}

            {nothingYet && (
              <div>
                <div className="is-flex is-justify-content-flex-start is-align-items-center mt-1 mb-4 is-size-4">
                  <FontAwesomeIcon icon={faInfoCircle} className="mr-2"></FontAwesomeIcon>
                  <div className="ml-2">
                    <b>Welcome!</b> Looks like you&apos;re new here. Let&apos;s get started. Click
                    new test up there and we&apos;ll help you start learning about your UniqueSelf.
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <ShareModal
          id="share-modal"
          isActive={!!this.state.sharingEvaluationId}
          evaluationId={this.state.sharingEvaluationId}
          email={this.props.identity.email}
          handleCancelClick={this.handleCancelShareClick}
        />
      </div>
    );
  }
}

function mapStateToProps(state: {
  evaluations: EvaluationState;
  questions: QuestionsState;
  identity: IdentityState;
}) {
  const { evaluations, questions, identity } = state;
  return { evaluations, questions, identity };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators(
    {
      setCompleteEvaluations,
      setPartialEvaluations,
      setQuestions,
      setActiveEvaluation,
      setTokens,
      pushLoading,
      popLoading
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
