import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type EvaluationState = {
  active?: Evaluation;
  complete: Evaluation[];
  partial: PartialEvaluation[];
};

export type Evaluation = {
  _id: string;
  name?: string;
  answers: Answer[];
  scores: Score[];
  results: Result[];
  createdAt: string;
  userId: string;
};

export type PartialEvaluation = {
  _id?: string;
  name?: string;
  answers: Answer[];
  createdAt: string;
};

export type Answer = {
  questionId: string;
  question: string;
  section: string;
  answer?: boolean;
};

export type Result = {
  category: string;
  blend: string;
  attributes: string;
  scores: Score[];
};

export type Score = {
  section: string;
  category: string;
  attribute: string;
  trueCount: number;
  falseCount: number;
  score: number;
  scorePercentage: number;
};

const initialState: EvaluationState = {
  active: undefined,
  complete: [],
  partial: []
};

export const evaluationSlice = createSlice({
  name: 'evaluations',
  initialState,
  reducers: {
    setCompleteEvaluations: (state, action: PayloadAction<Evaluation[]>) => ({
      ...state,
      complete: [...action.payload]
    }),
    setActiveEvaluation: (state, action: PayloadAction<Evaluation>) => ({
      ...state,
      active: { ...action.payload }
    }),
    setPartialEvaluations: (state, action: PayloadAction<PartialEvaluation[]>) => ({
      ...state,
      partial: [...action.payload]
    })
  }
});

export const { setCompleteEvaluations } = evaluationSlice.actions;
export const { setActiveEvaluation } = evaluationSlice.actions;
export const { setPartialEvaluations } = evaluationSlice.actions;

export default evaluationSlice.reducer;
