import { faStore, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnyAction, bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { setTokens, TokenState } from '../features/tokens/tokenSlice';
import { connect } from 'react-redux';
import req from '../Axios';
import { popLoading, pushLoading } from '../features/loading/loadingSlice';
import { PartialEvaluation } from '../features/evaluations/evaluationSlice';

type Props = {
  id: string | undefined;
  isActive: boolean;
  handleCancelClick: () => void;
  handleOpenStoreClick: () => void;
  tokens: TokenState;
  setTokens: typeof setTokens;
  pushLoading: typeof pushLoading;
  popLoading: typeof popLoading;
};

type State = {
  navigateToEvaluation: boolean;
  evaluationName: string;
  partial: PartialEvaluation | undefined;
};

class EvaluationSplashModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      navigateToEvaluation: false,
      evaluationName: '',
      partial: undefined
    };
  }

  handleOpenStoreModal = () => {
    this.handleCloseModal();
    this.props.handleOpenStoreClick();
  };

  handleStartTestClick = async () => {
    try {
      const { tokens } = this.props;

      this.props.pushLoading('evaluation-splash');

      // create the partial
      const partialResponse = await req.post('/partials', {
        name: this.state.evaluationName
      });

      const token = tokens[0];

      const tokenResponse = await req.post(`/tokens/${token._id}`, {});

      const updatedTokens = tokens.filter((t) => t._id !== tokenResponse.data._id);

      this.props.setTokens(updatedTokens);

      this.setState({
        navigateToEvaluation: true,
        partial: partialResponse.data
      });
    } catch (e) {
      this.props.popLoading('evaluation-splash');
    }
  };

  handleCloseModal = () => {
    this.setState({
      evaluationName: '',
      partial: undefined,
      navigateToEvaluation: false
    });

    this.props.handleCancelClick();
  };

  handleInputChange = (e: any) => {
    const { name, value } = e.target;
    const newState = { [name]: value } as Pick<State, keyof State>;
    this.setState(newState);
  };

  render() {
    let modalClasses = 'modal';

    if (this.props.isActive) modalClasses = 'modal is-active';

    const { tokens } = this.props;
    const hasTokens = tokens.length > 0;

    const { partial, evaluationName } = this.state;

    const name = !evaluationName ? 'UniqueSelf Test' : evaluationName;

    return (
      <div className={modalClasses}>
        {this.state.navigateToEvaluation && (
          <Navigate
            to={`/evaluation?partial=${partial?._id}`}
            replace={false}
            state={{ partial, evaluationName }}
          />
        )}
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Let&apos;s Get Started</p>
            <button className="delete" aria-label="close" onClick={this.handleCloseModal}></button>
          </header>
          <section className="modal-card-body">
            <p className="paragraph bold">Instructions</p>
            <p className="paragraph">Read each question once quickly.</p>
            <p className="paragraph">
              Answer instinctively on the basis of what you feel most of the time. Your initial
              response is the best answer.
            </p>

            <p className="paragraph">There are no right or wrong answers.</p>

            <p className="paragraph">
              If you need to take a break, your test will save and hold your place until you finish.
            </p>

            <p className="paragraph">
              Once you&apos;ve completed your test, you will see the results, and have the ability
              to print, share, or re-take the test!
            </p>
            <p className="paragraph">
              Optionally, you can enter a name for this test, so you can keep track of it later.
            </p>
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="What should we name this one? (Optional)"
                  value={this.state.evaluationName}
                  name="evaluationName"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <p className="bold mt-5">Remaining Tests</p>
            <p className="paragraph">
              You have <span className="bold">{tokens.length}</span> tests left.{' '}
              {!hasTokens && (
                <span>
                  You can <a href="#">buy tests</a> below, but you cannot begin a test until you
                  have purchased at least one.
                </span>
              )}{' '}
              {hasTokens && (
                <span>
                  Clicking &quot;
                  <a href="#" className="is-link" onClick={this.handleStartTestClick}>
                    Start the Test
                  </a>
                  &quot;&nbsp;will consume one of your available tests. You can always buy more with
                  the link below.
                </span>
              )}
            </p>
          </section>
          <footer className="modal-card-foot">
            <button
              className="button is-ghost is-hidden-mobile"
              onClick={this.handleOpenStoreModal}>
              <FontAwesomeIcon icon={faStore} className="mr-2"></FontAwesomeIcon>Buy More Tests
            </button>
            <div className="is-flex-grow-1"></div>
            <button className="button is-outlined is-link" onClick={this.handleCloseModal}>
              Nevermind
            </button>
            <button
              className="button is-link"
              onClick={this.handleStartTestClick}
              disabled={tokens.length == 0}>
              Start the Test
              <FontAwesomeIcon icon={faThumbsUp} className="ml-2"></FontAwesomeIcon>
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: { tokens: TokenState }) {
  const { tokens } = state;
  return { tokens };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators({ setTokens, pushLoading, popLoading }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationSplashModal);
