import { Component } from 'react';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IdentityState, clearIdentity } from '../features/identity/identitySlice';
import { Link, Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilePdf,
  faGift,
  faHome,
  faPlus,
  faSignOut,
  faStore
} from '@fortawesome/free-solid-svg-icons';
import EvaluationSplashModal from './EvaluationSplashModal';
import StoreModal from './StoreModal';
import SendGiftModal from './SendGiftModal';

type Props = {
  identity: IdentityState;
  clearIdentity: typeof clearIdentity;
  showNewTestButton?: boolean;
};

type State = {
  evaluationSplashModalIsActive: boolean;
  storeModalIsActive: boolean;
  showNewTestButton: boolean;
  sendGiftModalIsActive: boolean;
  showNav: boolean;
};
class Navigation extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      evaluationSplashModalIsActive: false,
      storeModalIsActive: false,
      showNewTestButton:
        this.props.showNewTestButton === undefined ? true : this.props.showNewTestButton,
      sendGiftModalIsActive: false,
      showNav: false
    };
  }

  toggleShowNav = () => {
    this.setState((state) => ({
      showNav: !state.showNav
    }));
  };

  handleCancelNewTestClick = () => {
    this.setState({
      evaluationSplashModalIsActive: false
    });
  };

  handleCloseSendGiftModalClick = () => {
    this.setState({
      sendGiftModalIsActive: false
    });
  };

  handleCloseStoreModalClick = () => {
    this.setState({
      storeModalIsActive: false
    });
  };

  handleSignOutClick = () => {
    this.props.clearIdentity();
    sessionStorage.clear();
  };

  handleNewTestClick = () => {
    this.setState({
      evaluationSplashModalIsActive: true
    });
  };

  handleOpenSendGiftModalClick = () => {
    this.setState({
      sendGiftModalIsActive: true
    });
  };

  handleOpenStoreModalClick = () => {
    this.setState({
      storeModalIsActive: true
    });
  };

  render() {
    let navbarMenuStyles = 'navbar-menu';
    let navbarBurgerStyles = 'navbar-burger';

    if (this.state.showNav) {
      navbarMenuStyles = 'navbar-menu is-active';
      navbarBurgerStyles = 'navbar-burger is-active';
    }

    return (
      <div className="container">
        <nav className="navbar" role="navigation" aria-label="main navigation">
          {!this.props.identity.access_token && <Navigate to="/login" replace={true} />}
          <div className="navbar-brand p-2">
            <Link to="/dashboard" className="logo"></Link>
            <a
              role="button"
              className={navbarBurgerStyles}
              aria-label="menu"
              aria-expanded="false"
              data-target="ust-navbar"
              onClick={this.toggleShowNav}>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div id="ust-navbar" className={navbarMenuStyles}>
            <div className="navbar-start">
              <Link
                to="/dashboard"
                className="navbar-item is-flex is-justify-content-space-between">
                Dashboard
                <FontAwesomeIcon icon={faHome} className="ml-2"></FontAwesomeIcon>
              </Link>
              <a
                onClick={this.handleOpenStoreModalClick}
                className="navbar-item is-flex is-justify-content-space-between">
                Store
                <FontAwesomeIcon icon={faStore} className="ml-2"></FontAwesomeIcon>
              </a>
              <a
                onClick={this.handleOpenSendGiftModalClick}
                className="navbar-item is-flex is-justify-content-space-between">
                Send a Gift <FontAwesomeIcon icon={faGift} className="ml-2"></FontAwesomeIcon>
              </a>
              <a
                href="https://cdn.shopify.com/s/files/1/1095/8916/files/ust_2019_color_notest.pdf?v=1666814090"
                target="_blank"
                className="navbar-item is-flex is-justify-content-space-between"
                rel="noreferrer">
                UniqueSelf PDF <FontAwesomeIcon icon={faFilePdf} className="ml-2"></FontAwesomeIcon>
              </a>
            </div>
            <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons">
                  <button className="button is-ghost" onClick={this.handleSignOutClick}>
                    Sign Out
                    <FontAwesomeIcon icon={faSignOut} className="ml-2"></FontAwesomeIcon>
                  </button>
                  {this.state.showNewTestButton && (
                    <button className="button is-link" onClick={this.handleNewTestClick}>
                      New Test
                      <FontAwesomeIcon icon={faPlus} className="ml-2"></FontAwesomeIcon>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div className="mobile-new-test-button is-hidden-tablet">
          <button className="button is-link is-fullwidth" onClick={this.handleNewTestClick}>
            New Test
            <FontAwesomeIcon icon={faPlus} className="ml-2"></FontAwesomeIcon>
          </button>
        </div>

        <EvaluationSplashModal
          id="evaluation-splash-modal"
          isActive={!!this.state.evaluationSplashModalIsActive}
          handleCancelClick={this.handleCancelNewTestClick}
          handleOpenStoreClick={this.handleOpenStoreModalClick}
        />
        <StoreModal
          id="store-modal"
          isActive={!!this.state.storeModalIsActive}
          handleCancelClick={this.handleCloseStoreModalClick}
        />
        <SendGiftModal
          id="store-modal"
          isActive={!!this.state.sendGiftModalIsActive}
          handleOpenStoreModal={this.handleOpenStoreModalClick}
          handleCancelClick={this.handleCloseSendGiftModalClick}
        />
      </div>
    );
  }
}

function mapStateToProps(state: { identity: IdentityState }) {
  const { identity } = state;
  return { identity };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators({ clearIdentity }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
