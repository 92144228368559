import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

export interface IdentityState {
  access_token?: string;
  email?: string;
  hasActiveSubscription?: boolean;
  purchaseReceipt?: string;
  purchasedAt?: Date;
  purchasedProductId?: string;
  refreshTokenCreatedAt?: Date;
  refresh_token?: string;
  tokenExpiresAt?: Date;
  userId?: string;
}

const initialState: IdentityState = {
  email: undefined,
  access_token: undefined,
  tokenExpiresAt: undefined,
  refresh_token: undefined,
  refreshTokenCreatedAt: undefined,
  hasActiveSubscription: false,
  purchaseReceipt: undefined,
  purchasedAt: undefined,
  purchasedProductId: undefined,
  userId: undefined
};

export const evaluateTokenRefresh = () => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return async (dispatch: Function, getState: Function) => {
    try {
      const { tokenExpiresAt } = await getState().identity;

      const diff = new Date(tokenExpiresAt).getTime() - new Date().getTime();

      if (diff <= 60) {
        await dispatch(refreshToken());
      }
    } catch (e) {
      // await dispatch(fireToast('We cannot refresh your session. Please logout and try again.'));
      // await dispatch(clearIdentity());
    }
  };
};

export const refreshToken = () => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return async (dispatch: Function, getState: Function) => {
    try {
      const state = await getState().identity;

      const { access_token, refresh_token } = state;

      const config = {
        headers: {
          authorization: `Bearer ${access_token}`
        }
      };

      const payload = {
        refreshToken: refresh_token
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/identity/refresh`,
        payload,
        config
      );

      await dispatch(setIdentity(response.data));

      // persist identity to session storage
      const identity = JSON.stringify(response.data);
      sessionStorage.setItem('identity', identity);
    } catch (e: any) {
      // await dispatch(fireToast('We cannot refresh your session. Please logout and try again.'));
    }
  };
};

export const identitySlice = createSlice({
  name: 'identity',
  initialState,
  reducers: {
    setIdentity: (state, action: PayloadAction<IdentityState>) => ({
      ...action.payload
    }),
    clearIdentity: () => ({
      ...initialState
    })
  }
});

export const { setIdentity, clearIdentity } = identitySlice.actions;

export default identitySlice.reducer;
